import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _0fad04f6 = () => interopDefault(import('../pages/access-limit/index.vue' /* webpackChunkName: "pages/access-limit/index" */))
const _436627fc = () => interopDefault(import('../pages/analysis-request/index.vue' /* webpackChunkName: "pages/analysis-request/index" */))
const _9d4ba62c = () => interopDefault(import('../pages/close/index.vue' /* webpackChunkName: "pages/close/index" */))
const _2dbcf754 = () => interopDefault(import('../pages/create-shorturl/index.vue' /* webpackChunkName: "pages/create-shorturl/index" */))
const _778dd36b = () => interopDefault(import('../pages/create-shorturl-List/index.vue' /* webpackChunkName: "pages/create-shorturl-List/index" */))
const _4836e006 = () => interopDefault(import('../pages/delete-plan/index.vue' /* webpackChunkName: "pages/delete-plan/index" */))
const _7db4745c = () => interopDefault(import('../pages/delete-setting/index.vue' /* webpackChunkName: "pages/delete-setting/index" */))
const _8260cdbc = () => interopDefault(import('../pages/env-setting/index.vue' /* webpackChunkName: "pages/env-setting/index" */))
const _f7b1005e = () => interopDefault(import('../pages/exam-end/index.vue' /* webpackChunkName: "pages/exam-end/index" */))
const _9b6dcafe = () => interopDefault(import('../pages/examinees/index.vue' /* webpackChunkName: "pages/examinees/index" */))
const _9248b860 = () => interopDefault(import('../pages/examining/index.vue' /* webpackChunkName: "pages/examining/index" */))
const _b286c7dc = () => interopDefault(import('../pages/identification/index.vue' /* webpackChunkName: "pages/identification/index" */))
const _0e5e1999 = () => interopDefault(import('../pages/identification-ai/index.vue' /* webpackChunkName: "pages/identification-ai/index" */))
const _a45cac70 = () => interopDefault(import('../pages/iframe/index.vue' /* webpackChunkName: "pages/iframe/index" */))
const _d01cef8e = () => interopDefault(import('../pages/inquiry/index.vue' /* webpackChunkName: "pages/inquiry/index" */))
const _0aa835ca = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _6701f5ce = () => interopDefault(import('../pages/logins/index.vue' /* webpackChunkName: "pages/logins/index" */))
const _3cfdd7db = () => interopDefault(import('../pages/logview/index.vue' /* webpackChunkName: "pages/logview/index" */))
const _2abdb827 = () => interopDefault(import('../pages/management/index.vue' /* webpackChunkName: "pages/management/index" */))
const _7a1c61ec = () => interopDefault(import('../pages/monitoring/index.vue' /* webpackChunkName: "pages/monitoring/index" */))
const _7e5fa4ba = () => interopDefault(import('../pages/privacy/index.vue' /* webpackChunkName: "pages/privacy/index" */))
const _caa64a80 = () => interopDefault(import('../pages/redirect/index.vue' /* webpackChunkName: "pages/redirect/index" */))
const _3dbcb1b9 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _48b33182 = () => interopDefault(import('../pages/alerting/system-error.vue' /* webpackChunkName: "pages/alerting/system-error" */))
const _4048c5ea = () => interopDefault(import('../pages/checkers/monitoring/index.vue' /* webpackChunkName: "pages/checkers/monitoring/index" */))
const _06a00c52 = () => interopDefault(import('../pages/alerting/_id.vue' /* webpackChunkName: "pages/alerting/_id" */))
const _2ad0a43e = () => interopDefault(import('../pages/create-shorturl/_id.vue' /* webpackChunkName: "pages/create-shorturl/_id" */))
const _521f0cec = () => interopDefault(import('../pages/examinees/_id/index.vue' /* webpackChunkName: "pages/examinees/_id/index" */))
const _1bc6b003 = () => interopDefault(import('../pages/examinees/_id/login.vue' /* webpackChunkName: "pages/examinees/_id/login" */))
const _15688d81 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/access-limit",
    component: _0fad04f6,
    name: "access-limit"
  }, {
    path: "/analysis-request",
    component: _436627fc,
    name: "analysis-request"
  }, {
    path: "/close",
    component: _9d4ba62c,
    name: "close"
  }, {
    path: "/create-shorturl",
    component: _2dbcf754,
    name: "create-shorturl"
  }, {
    path: "/create-shorturl-List",
    component: _778dd36b,
    name: "create-shorturl-List"
  }, {
    path: "/delete-plan",
    component: _4836e006,
    name: "delete-plan"
  }, {
    path: "/delete-setting",
    component: _7db4745c,
    name: "delete-setting"
  }, {
    path: "/env-setting",
    component: _8260cdbc,
    name: "env-setting"
  }, {
    path: "/exam-end",
    component: _f7b1005e,
    name: "exam-end"
  }, {
    path: "/examinees",
    component: _9b6dcafe,
    name: "examinees"
  }, {
    path: "/examining",
    component: _9248b860,
    name: "examining"
  }, {
    path: "/identification",
    component: _b286c7dc,
    name: "identification"
  }, {
    path: "/identification-ai",
    component: _0e5e1999,
    name: "identification-ai"
  }, {
    path: "/iframe",
    component: _a45cac70,
    name: "iframe"
  }, {
    path: "/inquiry",
    component: _d01cef8e,
    name: "inquiry"
  }, {
    path: "/login",
    component: _0aa835ca,
    name: "login"
  }, {
    path: "/logins",
    component: _6701f5ce,
    name: "logins"
  }, {
    path: "/logview",
    component: _3cfdd7db,
    name: "logview"
  }, {
    path: "/management",
    component: _2abdb827,
    name: "management"
  }, {
    path: "/monitoring",
    component: _7a1c61ec,
    name: "monitoring"
  }, {
    path: "/privacy",
    component: _7e5fa4ba,
    name: "privacy"
  }, {
    path: "/redirect",
    component: _caa64a80,
    name: "redirect"
  }, {
    path: "/terms",
    component: _3dbcb1b9,
    name: "terms"
  }, {
    path: "/alerting/system-error",
    component: _48b33182,
    name: "alerting-system-error"
  }, {
    path: "/checkers/monitoring",
    component: _4048c5ea,
    name: "checkers-monitoring"
  }, {
    path: "/alerting/:id?",
    component: _06a00c52,
    name: "alerting-id"
  }, {
    path: "/create-shorturl/:id?",
    component: _2ad0a43e,
    name: "create-shorturl-id"
  }, {
    path: "/examinees/:id",
    component: _521f0cec,
    name: "examinees-id"
  }, {
    path: "/examinees/:id/login",
    component: _1bc6b003,
    name: "examinees-id-login"
  }, {
    path: "/",
    component: _15688d81,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
